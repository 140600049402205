<template>
  <div class="warp">
    <el-form inline size="mini">
      <el-form-item label="Tournament ID:">
        <el-input v-model="extraData.id" type="number" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="Sport Name:">
        <select-box
          :value.sync="extraData.sportId"
          search-key="name"
          label-key="sportsName"
          value-key="id"
          :url="STD_SEARCH_SPORT"
          :disabled="extraData.id ? true : false"
          :queryOrderParams="{ page: 1, size: 500 }"
        />
      </el-form-item>
      <el-form-item label="Region:">
        <select-box
          :value.sync="extraData.regionId"
          search-key="name"
          label-key="name"
          value-key="id"
          :url="STD_SEARCH_REGION"
          :disabled="extraData.id ? true : false"
          :queryOrderParams="{ page: 1, size: 500,sportId:  extraData.sportId, deleteFlag: 1}"
        />
      </el-form-item>
      <el-form-item label="Tournament:">
        <input-autocomplete
          :extraData.sync="extraData.name"
          search-key="STD_SEARCH_TOURNAMENT"
          value-key="name"
          label-key="id"
          query-key="name"
          :disabled="extraData.id ? true : false"
          :queryOrderParams="{ page: 1, size: 100,sportId:  extraData.sportId, regionId:  extraData.regionId }"
        />
      </el-form-item>
      <el-form-item label="Ignore" >
        <el-select v-model="extraData.ignore" placeholder="" clearable="">
          <el-option label="Yes" :value="1"></el-option>
          <el-option label="No" :value="0"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Hot" >
        <el-select v-model="extraData.hot" placeholder="" clearable="">
          <el-option label="Yes" :value="1"></el-option>
          <el-option label="No" :value="0"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Validity" >
        <el-select v-model="extraData.isVisible" placeholder="" clearable="">
          <el-option label="Yes" :value="1"></el-option>
          <el-option label="No" :value="0"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Auto Push" >
        <el-select v-model="extraData.autoPush" placeholder="" clearable="">
          <el-option label="Yes" :value="true"></el-option>
          <el-option label="No" :value="false"></el-option>
        </el-select>
      </el-form-item>
       <el-form-item label="Creat Match" >
        <el-select v-model="extraData.autoCreateMatchDatasource" placeholder="" clearable="">
         <el-option v-for="item in  originData" :key="item.value"  :value="item.value" :label="item.label"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="search"
          >Search</el-button
        >
        <el-button @click="dialogAdd" >Add</el-button>
        <el-button type="success" style="margin-right: 10px" v-if="hasExport" :loading="downloding" @click="exportTournament">Export</el-button>
        <el-popover v-if="hasUpload" placement="bottom" @show="fileList = []">
          <el-button type="info" slot="reference">Upload</el-button>
          <el-upload
            class="upload-demo"
            :action="uploadUrl"
            :limit="1"
            :headers="{ token }"
            :on-success="uploadSuccess"
            :on-error="e => $message.error('Upload failed, Please check file')"
            :file-list="fileList">
            <el-button size="small" type="primary">Upload File</el-button>
            <div slot="tip" class="el-upload__tip">To update tournament level and outright level,<br> Uploaded file must include the Tournament Id,<br>
               Tournament Level, and Outright Level fields.
                If the <br>value of the field is empty,
                 it will not be updated</div>
          </el-upload>
        </el-popover>
      </el-form-item>
    </el-form>
    <el-table
      :data="list"
      stripe
      v-loading="loading"
      @sort-change="sort"
      :max-height="tableHeight"
      @expand-change="expandChange"
    >
      <el-table-column type="expand" width="150">
        <template slot-scope="props">
          <el-table
            class="expand-table"
            v-loading="expandData[props.row.id].loading"
            :data="expandData[props.row.id].data"
            style="width: 100%"
          >
            <el-table-column
              label="Source Data"
              width="140"
              align="center"
              prop="dataSourceValue"
            >
            </el-table-column>
            <el-table-column
              label="Source ID"
              width="150"
              align="center"
              prop="sourceTournamentId"
            ></el-table-column>
            <el-table-column
              label="Source Sport"
              width="140"
              align="center"
              prop="sourceSportName"
            ></el-table-column>
            <el-table-column
              label="Source Region"
              width="160"
              align="center"
              prop="sourceRegionName"
            >
            </el-table-column>
            <el-table-column
              label="Tournament Name"
              width="220"
              align="center"
              prop="name"
            ></el-table-column>
            <el-table-column
              label="Remove"
              width="160"
              align="center"
            >
              <template v-slot="{ row }">
                <status-icon
                  @click.native="remove(row, props.row)"
                  :status="'delete'"
                />
              </template>
            </el-table-column>
          </el-table>
        </template>
      </el-table-column>
      <el-table-column
        label="Tournament ID"
        width="150"
        align="center"
        prop="id"
        sortable="custom"
      ></el-table-column>
      <el-table-column
        label="Sport Name"
        min-width="140"
        align="center"
        prop="sportsName"
      ></el-table-column>
      <el-table-column
        label="Region"
        min-width="160"
        align="center"
        prop="regionName"
      >
        <template v-slot="{ row, $index }">
          <div>
            {{ row.regionName }}
            <el-button
              size="mini"
              type="primary"
              icon="el-icon-edit"
              circle
              v-if="$index !== editRowIndex"
              @click="handleEdit($index)"
            ></el-button>
            <input-autocomplete
              v-if="$index === editRowIndex"
              :row="{
                id: row.id,
                nameCode: row.nameCode
              }"
              :editRowIndex.sync="editRowIndex"
              :request-method="editData"
              @get-list="getList"
              :queryOrderParams="{ page: 1, size: 500, deleteFlag: 1 }"
              search-key="STD_SEARCH_REGION"
              value-key="name"
              label-key="id"
              query-key="regionName"
              update-key="regionId"
            />
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="Tournament Name"
        min-width="220"
        align="center"
        prop="name"
      ></el-table-column>
      <!-- <el-table-column
        label="Short Name"
        min-width="220"
        align="center"
        prop="nameSpell"
      ></el-table-column> -->
      <el-table-column
        label="Toumament Level"
        width="180"
        align="center"
        sortable="custom"
        prop="tournamentLevel"
      ></el-table-column>
      <el-table-column
        label="Outright Level"
        width="180"
        align="center"
        sortable="custom"
        prop="outrightLevel"
      ></el-table-column>
      <el-table-column
        label="Risk Level"
        width="130"
        align="center"
        sortable="custom"
        prop="riskLevel"
      ></el-table-column>
      <el-table-column
        label="Logo"
        min-width="120"
        align="center"
        prop="logoUrl"
      >
        <template v-slot="{ row }">
          <img
            class="table-team-logo"
            v-if="row.logoUrl"
            :src="row.logoUrl"
            alt="team-logo"
          />
        </template>
      </el-table-column>
      <el-table-column
        label="Introduction"
        min-width="120"
        align="center"
        prop="introduction"
      ></el-table-column>
      <el-table-column
        label="Remark"
        min-width="120"
        align="center"
        prop="remark"
      >
        <template v-slot="{ row }">
          <dropdown
            :row="{
              id: row.id,
              nameCode: row.nameCode
            }"
            :methodStr="methodStr"
            :text="`${row.remark || 'Please select'}`"
            @get-list="getList"
          />
        </template>
      </el-table-column>
      <el-table-column
        label="Hot"
        min-width="100"
        align="center"
      >
        <template v-slot="{ row }">
          <el-switch v-model="row.hot" :active-value="true" :inactive-value="false" @change="changeHot(row, $event)"></el-switch>
        </template>
      </el-table-column>
      <el-table-column
        label="Validity"
        min-width="100"
        align="center"
      >
        <template v-slot="{ row }">
          <el-switch v-model="row.switchStatus" :active-value="true" :inactive-value="false" @change="switchHandle(row)"></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="Ignore">
        <template v-slot="{row}">
          <el-switch v-model="row.ignore" :disabled="row.dataStatus === 1" :active-value="1" :inactive-value="0" @change="ignoreFn(row)"></el-switch>
        </template>
      </el-table-column>
      <!-- <el-table-column label="Repush" min-width="160" align="center">
        <template v-slot="{ row }">
           <el-button
           type="text"
            @click="changeRepushStatus(row, $event)"
          >Repush</el-button>
        </template>
      </el-table-column> -->
      <el-table-column label="Create Match" width="120">
        <template v-slot="{ row }">
          <el-select v-model="row.autoCreateMatchDatasource" @change="changeAutoCreateMatch(row)" placeholder="Select"  size="mini">
            <el-option v-for="item in originData" :key="item.value" :label="item.label" :value="item.value"></el-option>
            <!-- <el-option label=" " :value="0"></el-option> -->
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="Auto Push" width="100" align="center">
        <template v-slot="{row}">
          <el-switch v-model="row.autoPush" :disabled="row.dataStatus === 1"  @change="changeAutoPush(row)"></el-switch>
        </template>
      </el-table-column>
      <el-table-column
        label="Operation"
        min-width="100"
        align="center"
        prop="shortName"
      >
        <template v-slot="{ row }">
          <el-link type="primary" :underline="false" @click="edit(row)"
            >Edit</el-link
          >
        </template>
      </el-table-column>
      <el-table-column label="Log" min-width="160" align="center">
        <template v-slot="{ row }">
          <OperationType :dataType="'Standard_Tournament'" :dataId="row.id" />
        </template>
      </el-table-column>
    </el-table>
    <el-row type="flex" justify="end" style="margin: 20px 0 0 0">
      <el-pagination
        :total="total"
        :current-page="queryData.current"
        :page-size="queryData.size"
        :page-sizes="pageSizes"
        @current-change="changePage"
        @size-change="handleSizeChange"
        :layout="pageLayout"
      ></el-pagination>
    </el-row>
    <el-dialog
      :title="dialog.title"
      width="500px"
      :visible.sync="dialog.show"
      :close-on-click-modal="dialog.clickModal"
      :destroy-on-close="true"
    >
      <el-form
        :model="dialogForm"
        :rules="rules"
        label-width="160px"
        size="mini"
        :ref="dialog.ref"
      >
        <el-form-item label="Tournament ID:" v-if="dialogForm.id">
          {{ dialogForm.id }}
        </el-form-item>
        <el-form-item label="Tournament Name:" prop="name" required>
          <el-input
            placeholder="Please typing in tournament name"
            v-model="dialogForm.name"
            maxlength="200"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="Short Name:">
          <el-input
            placeholder="Please typing in Short name"
            v-model="dialogForm.nameSpell"
            maxlength="200"
          ></el-input>
        </el-form-item> -->
        <el-form-item label="Sports:" prop="sportId" required>
          <select-box
            :url="STD_SEARCH_SPORT"
            :queryOrderParams="{ page: 1, size: 500 }"
            :value.sync="dialogForm.sportId"
            :label="dialogForm.sportsName"
            search-key="name"
            label-key="sportsName"
            value-key="id"
          />
        </el-form-item>
        <el-form-item label="Region:" prop="regionId" required>
          <select-box
            :url="STD_SEARCH_REGION"
            :queryOrderParams="{ page: 1, size: 500, deleteFlag: 1}"
            :value.sync="dialogForm.regionId"
            :label="dialogForm.regionName"
            search-key="name"
            label-key="name"
            value-key="id"
          />
        </el-form-item>
        <el-form-item label="Tournament Level:">
          <el-input
            placeholder="Please typing in toumament level"
            v-model="dialogForm.tournamentLevel"
            v-Int
          ></el-input>
        </el-form-item>
        <el-form-item label="Logo:">
          <logo-upload :url.sync="dialogForm.logoUrl" />
        </el-form-item>
        <el-form-item label="Introduction:">
          <el-input
            placeholder="Please typing in introduction"
            v-model="dialogForm.introduction"
            maxlength="200"
          ></el-input>
        </el-form-item>
        <el-form-item label="Remark:">
          <el-input
            placeholder="Please typing in remark"
            v-model="dialogForm.remark"
            maxlength="200"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="dialogSubmit"
            :loading="dialog.isLoading"
            >Confirm</el-button
          >
          <el-button @click="dialogHide">Cancel</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  getTournamentList as getData,
  addTournament as addData,
  editTournament as editData,
  exportTournament,
  uploadTournament,
  getThirdTournamentById,
  updateThirdThirdTournamentById,
  tournamentValidity,
  tournamentHot,
  tournamentRePush,
  autoCreateMatchDatasource,
  autoPush
} from '@/service/standard'
import TableMixin from '@/mixins/standard/tableSelect'
import { Dropdown, InputAutocomplete, LogoUpload } from '@/components'
import { cloneDeep } from 'lodash'
import StatusIcon from '@/components/StatusIcon'
import { mapGetters } from 'vuex'
import {originData} from '@/utils/constants'
export default {
  name: 'Tournament',
  mixins: [TableMixin],
  components: { Dropdown, InputAutocomplete, LogoUpload, StatusIcon },
  data() {
    return {
      originData,
      methodStr: 'editTournament',
      editRowIndex: null,
      expandData: {},
      downloding: false,
      uploadUrl: '',
      fileList: [],
      extraData: {
        sportsName: '',
        regionName: '',
        tournamentName: '',
        ignore: 0,
        hot: '',
        isVisible: 1
      },
      rules: {
        name: [
          {
            message: 'Please typing in tournament name',
            required: true,
            trigger: 'blur',
            pattern: '[^ \x22]+'
          }
        ],
        sportId: [
          {
            message: 'Please select sports',
            required: true,
            trigger: 'change'
          }
        ],
        regionId: [
          {
            message: 'Please select region',
            required: true
          }
        ]
      }
    }
  },
  created() {
    this.uploadUrl = uploadTournament()
  },
  watch: {
    'extraData.id' () {
      if (this.extraData.id) {
        this.extraData.name = ''
        this.extraData.sportId = ''
        this.extraData.regionId = ''
      }
    }
  },
  computed: {
    ...mapGetters(['token']),
    ...mapGetters(['functionInfoList']),
     hasExport() {
      return this.functionInfoList.some((item) => {
        return (
          item.privilegeMark === 'TMC/Page/tournament/export' &&
          item.featureStatus !== 3
        )
      })
    },
     hasUpload() {
      return this.functionInfoList.some((item) => {
        return (
          item.privilegeMark === 'TMC/Page/tournament/uplaod' &&
          item.featureStatus !== 3
        )
      })
    }
  },
  methods: {
    changeAutoPush(row) {
      autoPush({tournamentId: row.id, autoPush: row.autoPush}).then(res => {
        if(res.code ===  0) {
          this.$message.success('Success')
          this.getList()
        }
      })
    },
    changeAutoCreateMatch(row) {
      autoCreateMatchDatasource({tournamentId: row.id, datasourceCode: row.autoCreateMatchDatasource}).then(res => {
        if(res.code ===  0) {
          this.$message.success('Success')
          this.getList()
        }
      })
    },
    changeRepushStatus(row) {
      tournamentRePush(row.id).then((res) => {
          if (res.code === 0) {
            this.$message({
              message: 'success',
              type: 'success'
            })
          }
        })
    },
    getData,
    addData,
    editData,
    uploadSuccess() {
      document.body.click()
      this.getList()
      this.fileList = []
    },
    exportTournament() {
      this.downloding = true
      const extraData = this.fliterParams(this.extraData) || {}
      exportTournament({...this.queryData, ...extraData}).finally(e => {
        this.downloding = false
      })
    },
    uploadTournament() {
      uploadTournament()
    },
    changeHot(row, e) {
      tournamentHot({
        hot: e,
        tournamentId: row.id
      }).then(res => {
        if (res.code === 0) {
        this.$message.success('Success')
        this.getList && this.getList()
        }
      })
    },
    handleEdit(index) {
      this.editRowIndex = index
    },
    async ignoreFn(row) {
      const data = await this.editData(row)
      if (data.code === 0) {
        this.$message.success('Success')
        this.getList && this.getList()
      }
    },
    getList() {
      this.loading = true
      const extraData = this.fliterParams(this.extraData) || {}
      this.getData({ ...this.queryData, ...extraData })
        .then(res => {
          const data = res.data
          if (res.code === 0) {
            this.list = data.records
            this.list = this.list.map(item => ({
              ...item,
              switchStatus: item.isVisible === 1,
            }))
            this.total = data.total
            this.loading = false
          }
        })
        .catch(err => {
          this.list = []
          this.loading = false
        })
    },
    edit(row) {
      const data = {
        id: row.id,
        nameCode: row.nameCode,
        remark: row.remark,
        sportId: row.sportId,
        regionId: row.regionId,
        name: row.name,
        oldTournamentName: row.name,
        tournamentLevel: row.tournamentLevel,
        logoUrl: row.logoUrl,
        introduction: row.introduction,
        regionName: row.regionName,
        sportsName: row.sportsName
      }
      this.dialogEdit(data)
    },
    async dialogSendData() {
      let data
      if (this.dialogForm.tournamentLevel === '') {
        this.dialogForm.tournamentLevel = 0
      }
      try {
        if (this.dialog.isEdit) {
          data = await this.editData(this.dialogForm)
        } else {
          data = await this.addData(this.dialogForm)
        }

        if (data.code === 0) {
          this.getList && this.getList()
          this.dialogHide()
          this.$message({
            message: 'Successful operation',
            type: 'success'
          })
        } else {
          this.dialog.isLoading = false
        }
      } catch (error) {
        this.dialog.isLoading = false
      }
    },
    expandChange(row) {
      let { id } = row
      this.upDataExpandData(id, {
        loading: true,
        data: null
      })
      getThirdTournamentById({tournamentId: id})
        .then(res => {
          this.upDataExpandData(id, {
            loading: false,
            data: res.data
          })
        })
        .catch(() => {
          this.upDataExpandData(id, {
            loading: false,
            data: null
          })
        })
    },
    upDataExpandData(id, data) {
      let expandData = cloneDeep(this.expandData)
      expandData[id] = data
      this.expandData = expandData
    },
    remove (row, propsRow) {
      this.$msgbox({
        title: '',
        type: 'warning',
        message: `Remove the mapping ?`,
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      }).then(action => {
        updateThirdThirdTournamentById({thirdTournamentId: row.id}).then(res => {
          if (res.code === 0) {
            this.$message({
              message: 'success',
              type: 'success'
            })
            this.expandChange(propsRow)
          }
        })
      })
    },
    switchHandle(row) {
      if (row.switchStatus) {
        this.$msgbox({
          title: '',
          type: 'warning',
          message: `Switch to invalid?`,
          showCancelButton: true,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No'
        }).then(action => {
          tournamentValidity({tournamentId: row.id, validity: row.switchStatus ? 1 : 0}).then(res => {
            if (res.code === 0) {
              this.$message({
                message: 'success',
                type: 'success'
              })
            }
            this.getList()
          })
          setTimeout(() => {
            this.getList()
          },1000)
        }).catch(action => {
          row.switchStatus = !row.switchStatus
        })
      } else {
        this.$msgbox({
          title: '',
          type: 'warning',
          message: `Switch to Valid?`,
          showCancelButton: true,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No'
        }).then(action => {
          tournamentValidity({tournamentId: row.id, validity: row.switchStatus ? 1 : 0}).then(res => {
            if (res.code === 0) {
              this.$message({
                message: 'success',
                type: 'success'
              })
            }
          })
          setTimeout(() => {
            this.getList()
          },1000)
        }).catch(action => {
          row.switchStatus = !row.switchStatus
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.table-team-logo {
  height: 32px;
  width: 32px;
}
/deep/ .el-table__expanded-cell {
  padding-left: 10px;
}
</style>
